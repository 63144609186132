import React from 'react';

import { Grid, Typography } from '@material-ui/core';

export const CenterText = ({ centerText }) => {
	return (
		<Grid container justifyContent='center'>
			<Typography
				variant='body1'
				style={{ textAlign: 'center', fontWeight: 500 }}>
				{centerText}
			</Typography>
		</Grid>
	);
};
