import React, { useState } from 'react';
import { navigate } from 'gatsby';

import { Container, Typography, Grid } from '@material-ui/core';
import { LoginCard } from './LoginCard';
import { SupportCard } from './SupportCard';
import { CenterText } from './CenterText';
import { CurrentCta } from './CurrentCta';

export const CurrentCustomersBody = ({
	loginCards,
	centerText,
	supportHeader,
	supportLinks,
	currentCta,
}) => {
	const [selected, setSelected] = useState(null);

	const handleHover = (e) => {
		const name = e.target.getAttribute('name');

		setSelected(name);
	};

	const handleCall = (e) => {
		window.open(`${supportLinks[0].slug.current}`, '_self');
	};

	const handleNavigate = (e) => {
		window.open(`${supportLinks[1].slug.current}`, '_blank');
	};
  return (
		<Container>
      {!!currentCta.ctaText && (
			  <CurrentCta currentCta={currentCta} />
      )}
			<Grid container spacing={2} style={{ padding: '2rem 0' }}>
				{loginCards.map((card, index) => (
					<LoginCard card={card} key={index} />
				))}
			</Grid>
			<Grid container style={{ padding: '4rem 0' }}>
				<CenterText centerText={centerText} />
			</Grid>
			<Grid container style={{ paddingTop: '2rem' }} justifyContent='center'>
				<Typography
					variant='h2'
					color='primary'
					style={{ textAlign: 'center' }}>
					{supportHeader}
				</Typography>
			</Grid>

			<Grid container style={{ padding: '4rem 0' }}>
				<SupportCard
					cardContent={supportLinks[0]}
					selected={selected}
					handleHover={handleHover}
					setSelected={setSelected}
					handleClick={handleCall}
				/>
				<SupportCard
					cardContent={supportLinks[1]}
					selected={selected}
					handleHover={handleHover}
					setSelected={setSelected}
					handleClick={handleNavigate}
				/>
			</Grid>
		</Container>
	);
};
