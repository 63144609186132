import React from 'react';

import {
	Container,
	Grid,
	Card,
	CardHeader,
	CardContent,
	Button,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#5A7184',
		'& a': {
			color: theme.workwaveBlue,
		},
	},
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		borderRadius: '6px',
		// maxWidth: '289px',
		position: 'relative',
		// paddingTop: '2.75rem',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		transition: 'transform 0.25s',
	},
	button: {
		padding: '6px 55px',
		marginTop: '1rem',
		opacity: 1,
		fontSize: '1.1rem',
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
	},
	title: {
		fontSize: '1.4rem',
		fontWeight: 700,
		textAlign: 'center',
		color: '#183B56',
	},
	pos: {
		textAlign: 'center',
		color: '#4B5B69',
	},
}));

export const LoginCard = ({ card }) => {
	const classes = useStyles();
	return (
		<Grid item xs={12} md={6} lg={4}>
			<Card className={classes.root} elevation={3} style={{ height: '200px' }}>
				<CardHeader
					style={{
						background: card.accentColor?.hexValue ?? '#002D5C',
						padding: '8px',
						width: '100%',
					}}
				/>
				<CardContent style={{ padding: '2rem' }}>
					<Grid
						container
						direction='column'
						alignItems='center'
						justifyContent='space-between'
						spacing={2}
						item
						xs={12}>
						<Grid item>
							<Typography
								variant='h6'
								color='primary'
								className={classes.title}>
								{card.title}
							</Typography>
						</Grid>

						<Grid item>
							<a
								href={card.ctaLink}
								target='_blank' aria-label="Opens in a new tab"
								style={{ textDecoration: 'none' }}>
								<Button
									variant='contained'
									size='large'
									color='primary'
									className={classes.button}
									style={{
										background: card.accentColor?.hexValue ?? '#002D5C',
									}}>
									{card.ctaText}
								</Button>
							</a>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};
