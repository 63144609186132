import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';

import {
	Button,
	Typography,
	Grid,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';
import { Link } from 'gatsby';

import FormModalContext from '../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	bgImg: {
		padding: '1rem',
		background: '#0F95A4',
		// [theme.breakpoints.down('lg')]: {
		// 	padding: '2rem',
		// },
		// [theme.breakpoints.down('md')]: {
		// 	padding: '1.5rem .625rem',
		// },
	},
	topLine: {
		fontSize: '1.25rem',
		zIndex: 1,
	},
	bodyFirstLine: {
		textTransform: 'uppercase',
		fontWeight: 400,
		lineHeight: 1,
		fontSize: '1.8rem',
		marginBottom: 0,
		zIndex: 1,
	},
	bodySecondLine: {
		fontWeight: 700,
		fontSize: '3rem',
		lineHeight: 1,
		textTransform: 'uppercase',
		zIndex: 1,
	},
	divider: {
		width: '1px',
		height: '50px',
		background: '#fff',
		margin: '0 15px',
	},
	button: {
		borderRadius: '30px',
		width: '640px',
		'& $label': {
			fontSize: '1.75rem',
			fontWeight: 500,
		},
		[theme.breakpoints.down('sm')]: {
			width: '300px',
		},
		[theme.breakpoints.down('xs')]: {
			width: '240px',
		},
	},
}));

export const CurrentCta = ({ currentCta }) => {
	const classes = useStyles();

	const { handleModalClick } = useContext(FormModalContext);

	const {
		ctaTopLine,
		_rawCtaBody,
		ctaText,
		ctaTopLineLogo,
		ctaBackgroundRight,
		ctaLink,
		internalLink,
	} = currentCta;

	const md = useMediaQuery('(max-width: 960px)');
	return (
		<div style={{ paddingBottom: '4rem' }}>
			<div
				style={{
					borderRadius: '9px',
					boxShadow: '0 10px 40px 0px rgba(0,0,0,0.2)',
					overflow: 'hidden',
					// backgroundSize: 'cover',
					position: 'relative',
				}}
				className={classes.bgImg}>
				{!md ? (
					<GatsbyImage
						image={ctaBackgroundRight?.asset.gatsbyImageData}
						// imgStyle={{ objectFit: 'contain' }}
						style={{
							position: 'absolute',
							right: 0,
							top: 0,
							height: '100%!important',
							zIndex: 1,
						}}
					/>
				) : null}

				<Grid
					item
					container
					direction='column'
					lg={9}
					style={{ color: 'white', zIndex: 2 }}>
					<Grid
						container
						item
						alignItems='center'
						style={{
							flexWrap: 'nowrap',
							marginBottom: '1rem',
							fontFamily: 'Poppins',
						}}>
						<GatsbyImage image={ctaTopLineLogo?.asset?.gatsbyImageData} />
						<div className={classes.divider} />
						<Typography variant='h5' className={classes.topLine}>
							{ctaTopLine}
						</Typography>
					</Grid>
					<Grid container item style={{ zIndex: 1 }}>
						<PortableText
							content={_rawCtaBody}
							serializers={{
								h3: ({ children }) => (
									<Typography variant='h3' className={classes.bodyFirstLine}>
										{children}
									</Typography>
								),
								h2: ({ children }) => (
									<Typography variant='h2' className={classes.bodySecondLine}>
										{children}
									</Typography>
								),
							}}
						/>
					</Grid>
					<a href={ctaLink} target='_blank' aria-label="Opens in a new tab">
						<Button
							color='primary'
							variant='contained'
							size='large'
							className={classes.button}
							style={{ marginTop: '1rem', zIndex: 2 }}>
							{ctaText}
						</Button>
					</a>
				</Grid>
			</div>
		</div>
	);
};
