import React, {
	useRef,
	useContext,
	useState,
	lazy,
	Suspense,
	useEffect,
} from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Hero } from '../components/General/Hero';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import FormModalContext from '../context/FormModalContext';
import { SEO } from '../components/SEO';
import { ProductFeatureBody } from '../components/ProductFeature/ProductFeatureBody';

import { CurrentCustomersBody } from '../components/CurrentCustomers/CurrentCustomersBody';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	testimonialTitle: {
		color: theme.workwaveBlue,
		textAlign: 'center',
		fontWeight: 'bold',
		marginBottom: '2rem',
		marginTop: '2rem',
	},

	// centerBackground: {
	// 	background:
	// 		'linear-gradient(339.51deg, #002D5C 26.73%, #055291 46.94%, #2F7FC1 67.6%, #002D5C 88.26%)',
	// 	padding: '6rem 0',
	// },

	formCont: {
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '16rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
	testimonialBackground: {
		backgroundSize: 'cover',
		height: '600px',
		width: '99vw',
		maxWidth: '100%',
		[theme.breakpoints.down('md')]: {
			width: '100vw',
			height: '500px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '400px',
		},
	},
	testimonialContainer: {
		marginTop: '-35rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-30rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-25rem',
		},
	},
}));

const CurrentCustomersPage = ({ data, location }) => {
	const currentCustomers = data.currentCustomers.edges[0].node;
	const classes = useStyles();
	const formRef = useRef();
	const theme = useTheme();

	//state for selected card for features
	const [selectedFeature, setSelectedFeature] = useState(null);

	// const { modalOpen, setModalOpen, selectedThumbnail } = useContext(
	// 	FeatureThumbnailContext
	// );

	useEffect(() => {
		setSelectedFeature(currentCustomers.featureSection[0]?._key);
	}, []);

	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);

	const {
		metaTitle,
		metaDescription,
		hero,
		marketoId,
    pardotUrl,
		contactForm,
		formBgImage,
		resources,
		resourceTitle,
		_rawResourceBody,
		loginCards,
		centerText,
		supportHeader,
		supportLinks,
		testimonialBackground,
		currentCta,
	} = currentCustomers;

	const sm = useMediaQuery('(max-width: 600px)');
	const xs = useMediaQuery('(max-width: 481px)');
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} />
			<CurrentCustomersBody
				loginCards={loginCards}
				centerText={centerText}
				supportHeader={supportHeader}
				supportLinks={supportLinks}
				currentCta={currentCta}
			/>
			<ProductFeatureBody body={currentCustomers} />
			<WaveDownSVG height='213' width='100%' fill='#FFFFFF' />

			<div>
				<WaveDownSVG height='213' width='100%' fill={'#FFFFFF'} />
			</div>

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				{!!selectedFeature ? (
					<Form
						formId={marketoId}
            pardotUrl={pardotUrl}
						contactForm={contactForm}
						privacy
						modal={false}
						location={location}
					/>
				) : null}
			</div>
			<WaveUpSVG height='213' width='100%' fill='#FFFFFF' />
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
		</>
	);
};

export const query = graphql`
	query CurrentCustomersPageQuery {
		currentCustomers: allSanityCurrentCustomers {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					currentCta {
						ctaTopLine
						_rawCtaBody
						ctaText
						ctaTopLineLogo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaBackgroundRight {
							asset {
								gatsbyImageData(
									placeholder: BLURRED
									width: 420
									height: 320
									fit: FILLMAX
								)
							}
						}
						ctaLink
						internalLink
					}
					loginCards {
						title
						ctaText
						ctaLink
						accentColor {
							hexValue
						}
					}
					centerText
					supportHeader
					supportLinks {
						title
						faIcon
						iconColor {
							hexValue
						}
						name
						description
						slug {
							current
						}
					}
					featureHeader
					featureSection {
						_key
						title
						cardTitle
						cardFaIcon
						cardIconColor {
							hexValue
						}
						bodySubHeader
						bodyHeader
						image {
							featureImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
						}
						videoVariant
						androidLink
						iosLink
						qbLink
						# svgVariant
						thumbnailVideoUrl
						ctaText
						ctaLink
						_rawBodyCopy
						internalLink
					}
					testimonialTitle
					testimonial {
						title
						header
						testimonialText
						nameAndTitle
						company
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					testimonialBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					customerLogosHeader
					customerLogos {
						title
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					contactForm {
						header
						privacyPolicy
					}
					formBgImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					marketoId
          pardotUrl
					resourceTitle
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
				}
			}
		}
	}
`;

export default CurrentCustomersPage;
